import React from "react";
import Photos from "../Photos";

function Texts() {
  return (
    <div className="secondaryWrapper">
      <div className="leftSide">
        <h1 className="neonText">Texts</h1>
        <div className="cv">
          <ul>
            <h2 className="underlined">Articles</h2>
            <li>
              Vassallo, J. S. (2024). Exploring Musical Procedural Rhetoric:
              Computational Influence on Compositional Frameworks and Methods in
              the piece "Elevator Pitch". International Journal of Music
              Science, Technology and Art. 6, 1-16.
              <li>
                <a
                  className="link"
                  href="https://doi.org/10.48293/IJMSTA-114"
                  target="_blank"
                >
                  https://doi.org/10.48293/IJMSTA-114
                </a>
              </li>
            </li>
            <li>
              Vassallo, J. S. (2022). Machinic automation in the process of text
              and music composition: The versificator. Proceedings - The 7th
              Colloquium on Artistic Research in Performing Arts.
              <li>
                <a
                  className="link"
                  href="https://nivel.teak.fi/carpa7/machinic-automation-in-the-process-of-text-and-music-composition//"
                  target="_blank"
                >
                  https://nivel.teak.fi/carpa7/machinic-automation-in-the-process-of-text-and-music-composition/
                </a>
              </li>
            </li>
            <li>
              Vassallo, J. S. (2022). Symbolic Musical Resynthesis As An
              Ekphrastic Compositional Practice Using Computational Methods.
              Culture Crossroads, 22, 129–147.
              <li className="link">
                <a
                  className="link"
                  href="https://doi.org/10.55877/cc.vol22.443"
                  target="_blank"
                >
                  https://doi.org/10.55877/cc.vol22.443
                </a>
              </li>
            </li>

            <h2 className="underlined">M.A. Thesis</h2>
            <li>
              Vassallo, J. S. (2019). Music expert-novice differences in speech
              perception. University of Victoria Repository Dspace.
              <li>
                <a
                  className="link"
                  href="http://hdl.handle.net/1828/11061"
                  target="_blank"
                >
                  http://hdl.handle.net/1828/11061
                </a>
              </li>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Texts;
