import React from "react";

function Photos() {
    return (
        <div className="rightSide">
            <img
                className="imgBody"
                src="img/248E0274-38B9-4727-B024-71566C2F2190_1_105_c.jpeg "
            />
            <img className="imgBody" src="img/juan_edited_edited.jpg" />
            <img
                className="imgBody"
                src="img/31224930_952206774948132_5286944826858668032_n.jpg"
            />
        </div>
    );
}
export default Photos;
