import React from "react";

function Contact() {
  return (
    <div className="secondaryWrapper whenSubComponent">
      <div className="width">
        <h1 className="neonText">Contact</h1>

        <div className="contact">
          <div className="indivContact">
            <h3 className="subtitle2">...</h3>
            <h3 className="nombre">Juan Sebastian Vassallo</h3>
            <h3 className="subtitle2">...</h3>

            <h3 className="subtitle2">Lars Hilles Gate 3 </h3>
            <h3 className="subtitle2">5015 Bergen (Norway)</h3>
            <h3 className="subtitle2">juan.vassallo@uib.no</h3>
            <h3 className="subtitle2">...</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
